import React, { useContext, useEffect } from 'react'
import './Sneck.scss'
import MyContext from '../Context/MyContext'
import { TiTick } from 'react-icons/ti'

const Sneck = () => {

    const { message, sneck, setSneck } = useContext(MyContext)

    useEffect(() => {
        if (sneck) {
             setTimeout(() => {
                setSneck(false)
            }, 2500)

           
           
        }
    }, [sneck, setSneck]) 

    return (
        <>
            {sneck &&
                <div className='sneck-main'>
                    <TiTick /> {message}
                </div>
            }
        </>
    )
}

export default Sneck
