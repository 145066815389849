import React, { useContext } from 'react'
import './Sidebar.scss'
import MyContext from '../Common/Context/MyContext'

import Json from './Home/SideJson';
import { CgClose } from 'react-icons/cg';
import { MdLabelImportantOutline, MdOutlineLabelImportant } from 'react-icons/md';
import { RiEmotionNormalFill, RiEmotionNormalLine } from 'react-icons/ri';

const Sidebar = () => {

  const{Navigate,id,sends,save,setSave,over,setDrop,handleNormal,data,handleMove,setNmi,setImportant,setStatus,setOver} = useContext(MyContext)

  const handleNavigate = (item) =>{
    Navigate(item.route)
    setSave(item.route)
    setDrop('back')
    setNmi(false)
    setImportant(false)
    setStatus(false)
    localStorage.setItem('route',item.route)

  }

 
  
  return (
    <div className='sidebar-main'>


    <div className='hover-view' style={{height:over}}>
    <h3>{sends} {id}</h3>
    <span onClick={()=>setOver('0px')}><CgClose/></span>
   

    {
      data && data.length>0 ?( data.map((i)=>{
        return(
          <>
        
       
          <div className='listo-item'><li className='mop'>{i.name}</li> 

          <div className='icon-m'>
          {
            i.important===true? <MdOutlineLabelImportant />:
           <MdLabelImportantOutline  onClick={() => handleMove(i._id)}/>
           }

           { i.normal===true?
            <RiEmotionNormalFill/>:
           <RiEmotionNormalLine onClick={() => handleNormal(i._id)}/>
           
           }

           </div>
          
          
          </div>
          </>
        )
      })
        ):
       <div className="nod">no data available</div>
    }
    </div>



   {

    Json.map((item)=>{
      return(
        <li className={save===item.route ? 'acto':'peto'} onClick={()=>handleNavigate(item)}>{item.icon} {item.title}</li>
      )
    })

   
   }
 
 
    
 
    
    
    </div>
  )
}

export default Sidebar