import React, { useContext, useEffect } from 'react'
import './Enquiry.scss'
import MyContext from '../../Common/Context/MyContext'
import { RiDeleteBin6Line } from 'react-icons/ri'

const Enquiry = () => {

  const { enquiryData, fetchData,handleDelete } = useContext(MyContext)

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="enquiry-table">

    {enquiryData && enquiryData.length>0?
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Message</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {enquiryData.map((item) => (
            <tr key={item._id}>
              <td>{item.name}</td>
              <td>{item.phone}</td>
              <td>{item.message}</td>
              <td>{item.email}</td>
              <div className='overlay'><RiDeleteBin6Line  onClick={() => handleDelete(item._id)} /></div>
            </tr>
          ))}
        </tbody>
      </table>:
      <p>No Data Available</p>

        }
    </div>
  )
}

export default Enquiry
