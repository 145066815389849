import React, { useContext, useEffect } from 'react'
import './Home.scss'
import Pass from '../../assets/passport.png'
import Aadhar from '../../assets/aadhar.png'
import Panc from '../../assets/pan.png'
import voteri from '../../assets/voter.png'
import dl from '../../assets/dl.png'
import ot from '../../assets/other.png'
import MyContext from '../../Common/Context/MyContext'
import { MdNewReleases, MdOutlineFiberSmartRecord, MdOutlineLabelImportant } from 'react-icons/md'
import { RiEmotionNormalFill } from 'react-icons/ri'
import { LuNewspaper } from 'react-icons/lu'
import { BiSolidShow } from 'react-icons/bi'
import { IoMdStopwatch } from 'react-icons/io'

const Home = () => {
  const { fetchpassport, setSave,passport,Navigate,handlePassnormal } = useContext(MyContext)

  const handleNavigate = (item) =>{
    Navigate(item)
    setSave(item)
  }

  useEffect(() => {
    fetchpassport()
  }, [])

  const passn =  passport && passport.filter(p=>p.idType==='passport')
  const passi = passport && passport.filter(p=>p.idType==='passport' && p.important===true)
  const passp = passport && passport.filter(p=>p.idType==='passport' && p.normal===true)
  const news = passport && passport.filter(p=>p.idType==='passport' && p.work.includes('newspaper'))
  const status = passport && passport.filter(p=>p.idType==='passport' && p.status===true)
  const paapoint = passport && passport.filter(p=>p.idType==='passport' && new Date(p.date).toLocaleDateString('en-GB')=== new Date().toLocaleDateString('en-GB'))
  const newi = passport && passport.filter(p=>p.idType==='passport' && p.status===false && p.normal===false && p.important===false)
  const aadhar = passport && passport.filter(p=>p.idType==='aadhar')
  const ai = passport && passport.filter(p=>p.idType==='aadhar' && p.important===true)
  const an = passport && passport.filter(p=>p.idType==='aadhar' && p.normal===true)
  const sa = passport && passport.filter(p=>p.idType==='aadhar' && p.status===true)
  const asni = passport && passport.filter(p=>p.idType==='aadhar' && p.status===false && p.normal===false && p.important===false)
  const asmart = passport && passport.filter(p=>p.idType==='aadhar' && p.work.includes('smart'))
  const pan = passport && passport.filter(p=>p.idType==='pan')
  const pi = passport && passport.filter(p=>p.idType==='pan' && p.important===true)
  const pn = passport && passport.filter(p=>p.idType==='pan' && p.normal===true)
  const ps = passport && passport.filter(p=>p.idType==='pan' && p.status===true)
  const pnew = passport && passport.filter(p=>p.idType==='pan' && p.status===false && p.normal===false && p.important===false)
  const voter = passport && passport.filter(p=>p.idType==='voter')
  const vi = passport && passport.filter(p=>p.idType==='voter' && p.important===true)
  const vn = passport && passport.filter(p=>p.idType==='voter' && p.normal===true)
  const vs = passport && passport.filter(p=>p.idType==='voter' && p.status===true)
  const vnew = passport && passport.filter(p=>p.idType==='voter' && p.status===false && p.normal===false && p.important===false)
  const driving = passport && passport.filter(p=>p.idType==='driving')
  const di = passport && passport.filter(p=>p.idType==='driving' && p.important===true)
  const dn = passport && passport.filter(p=>p.idType==='driving' && p.normal===true)
  const ds = passport && passport.filter(p=>p.idType==='driving' && p.status===true)
  const newd = passport && passport.filter(p=>p.idType==='driving' && p.status===false && p.normal===false && p.important===false)
  const other = passport && passport.filter(p=>p.idType==='other')
  const oi = passport && passport.filter(p=>p.idType==='other' && p.important===true)
  const on = passport && passport.filter(p=>p.idType==='other' && p.normal===true)
  const os = passport && passport.filter(p=>p.idType==='other' && p.status===true)
  const newo = passport && passport.filter(p=>p.idType==='other' && p.status===false && p.normal===false && p.important===false)



  return (
    <div className='home-main'>
      <div className='cards-section'>


        {/* Passport Section */}
        <div className='card' >
        <div className='row1'>
          <img src={Pass} alt="Passport" />
          <h3>Passport</h3>
          <p onClick={() =>handleNavigate('/passport')}>Total: {passn && passn.length}</p>
          </div>
          <div className='card-info'>
           
          
            <p onMouseEnter={()=>handlePassnormal(passp,'passport',<RiEmotionNormalFill />)}><RiEmotionNormalFill />{passp && passp.length}</p>
            <p  onMouseEnter={()=>handlePassnormal(passi,'passport',<MdOutlineLabelImportant />)}><MdOutlineLabelImportant/>{passi && passi.length}</p>
            <p  onMouseEnter={()=>handlePassnormal(news,'passport',<LuNewspaper/>)}><LuNewspaper/>{news && news.length}</p>
            <p onMouseEnter={()=>handlePassnormal(status,'passport',<BiSolidShow/>)}><BiSolidShow/>{status && status.length}</p>
            <p onMouseEnter={()=>handlePassnormal(newi,'passport',<MdNewReleases/>)}><MdNewReleases/>{newi && newi.length}</p>
            <p onMouseEnter={()=>handlePassnormal(paapoint,'passport',<IoMdStopwatch />)}><IoMdStopwatch />{paapoint && paapoint.length}</p>

          </div>
        </div>

        {/* Aadhar Card Section */}
        <div className='card' >
        <div className='row1'>
          <img src={Aadhar} alt="Aadhar Card" />
          <h3>Aadhar Card</h3>
          <p onClick={() => handleNavigate('/aadhar-card')}>Total: {aadhar && aadhar.length}</p>
          </div>
          <div className='card-info'>
          
            <p onMouseEnter={()=>handlePassnormal(an,'aadhar',<RiEmotionNormalFill/>)}><RiEmotionNormalFill/>{an && an.length}</p>
            <p onMouseEnter={()=>handlePassnormal(ai,'aadhar',<MdOutlineLabelImportant/>)}> <MdOutlineLabelImportant/> {ai && ai.length}</p>
           
            <p onMouseEnter={()=>handlePassnormal(sa,'aadhar',<BiSolidShow/>)}><BiSolidShow/>{sa && sa.length}</p>
            <p onMouseEnter={()=>handlePassnormal(asni,'aadhar',<MdNewReleases/>)}><MdNewReleases />{asni && asni.length}</p>
            <p onMouseEnter={()=>handlePassnormal(asmart,'aadhar',<MdOutlineFiberSmartRecord/>)}><MdOutlineFiberSmartRecord/>{asmart && asmart.length}</p>
          </div>
        </div>

        {/* PAN Card Section */}
        <div className='card' >
        <div className='row1'>
          <img src={Panc} alt="PAN Card" />
          <h3>PAN Card</h3>
          <p onClick={() => handleNavigate('/pan-card')}>Total: {pan && pan.length}</p>
          </div>
          <div className='card-info'>
           
            <p onMouseEnter={()=>handlePassnormal(pn,'pan card',<RiEmotionNormalFill/>)}><RiEmotionNormalFill /> {pn && pn.length}</p>
            <p onMouseEnter={()=>handlePassnormal(pi,'pan card',<MdOutlineLabelImportant/>)}><MdOutlineLabelImportant/> {pi && pi.length}</p>
           
            <p onMouseEnter={()=>handlePassnormal(ps,'pan card',<BiSolidShow/>)}><BiSolidShow/>{ps && ps.length}</p>
            <p onMouseEnter={()=>handlePassnormal(pnew,'pan card',<MdNewReleases/>)}><MdNewReleases />{pnew && pnew.length}</p>
          </div>
        </div>

        {/* Voter ID Section */}
        <div className='card' >
        <div className='row1'>
        <img src={voteri} alt="Voter ID" />
        <h3>Voter ID</h3>
        <p onClick={() => handleNavigate('/voter-id')}>Total: {voter && voter.length}</p>
        </div>
         
          <div className='card-info'>
           
            <p onMouseEnter={()=>handlePassnormal(vn,'voter id',<RiEmotionNormalFill />)}><RiEmotionNormalFill /> {vn && vn.length}</p>
            <p onMouseEnter={()=>handlePassnormal(vi,'voter id',<MdOutlineLabelImportant />)}><MdOutlineLabelImportant/> {vi && vi.length}</p>
           
            <p onMouseEnter={()=>handlePassnormal(vs,'voter id',<BiSolidShow />)}><BiSolidShow/>{vs && vs.length}</p>
            <p onMouseEnter={()=>handlePassnormal(vnew,'voter id',<MdNewReleases />)}><MdNewReleases />{vnew && vnew.length}</p>
          </div>
        </div>

        {/* Driving License Section */}
        <div className='card' >
        <div className='row1'>
          <img src={dl} alt="Driving License" />
          <h3>Driving License</h3>
          <p onClick={() => handleNavigate('/driving-license')}>Total: {driving && driving.length}</p>
          </div>
          <div className='card-info'>
           
            <p onMouseEnter={()=>handlePassnormal(dn,'driving',<RiEmotionNormalFill />)}><RiEmotionNormalFill /> {dn && dn.length}</p>
            <p onMouseEnter={()=>handlePassnormal(di,'driving',<MdOutlineLabelImportant />)}><MdOutlineLabelImportant/> {di && di.length}</p>
           
            <p onMouseEnter={()=>handlePassnormal(ds,'driving',<BiSolidShow />)}><BiSolidShow/>{ds && ds.length}</p>
            <p onMouseEnter={()=>handlePassnormal(newd,'driving',<MdNewReleases />)}><MdNewReleases />{newd && newd.length}</p>
          </div>
        </div>


          {/* OtherSection */}
          <div className='card' >
          <div className='row1'>
          <img src={ot} alt="Other-work" />
          <h3>Other Work</h3>
          <p onClick={() => handleNavigate('/other-work')}>Total: {other && other.length}</p>
          </div>
          <div className='card-info'>
           
            <p onMouseEnter={()=>handlePassnormal(on,'others',<RiEmotionNormalFill />)}><RiEmotionNormalFill /> {on && on.length}</p>
            <p onMouseEnter={()=>handlePassnormal(oi,'others',<MdOutlineLabelImportant/>)}><MdOutlineLabelImportant/> {oi && oi.length}</p>
           
            <p onMouseEnter={()=>handlePassnormal(os,'others',<BiSolidShow/>)}><BiSolidShow/>{os && os.length}</p>
            <p onMouseEnter={()=>handlePassnormal(newo,'others',<MdNewReleases/>)}><MdNewReleases />{newo && newo.length}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
