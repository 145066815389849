import React, {useState } from 'react'
import MyContext from './MyContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const Provider = ({children}) => {
 const url = 'https://bottom-seven.vercel.app'
    // const url = 'http://localhost:9000'
    const Navigate = useNavigate()
    const [enquiryData, setEnquiryData] = useState([]);
    const[message,setMessage]=useState('')
    const[sneck,setSneck]=useState(false)

const[loading,setLoading]=useState(false)
    const fetchData = async () => {
      setLoading(true);
  
      try {
        const { data } = await axios.get(`${url}/enquiry`);
  
        const sortedData = data.sort((a, b) => b._id.localeCompare(a._id));
  
        setEnquiryData(sortedData);
      } catch (error) {
        console.error('Error fetching enquiry data:', error);
      
      } finally {
        setLoading(false);
      }
    };

    const[work,setWork]=useState([])
    const fetchWork = async () => {
      setLoading(true);
  
      try {
        const {data} = await axios.get('https://databack-tumt.vercel.app/accept');
       
        setWork(data);
      } catch (error) {
        console.error('Error fetching attendance data:', error);
        setError('Error fetching attendance data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    const handleWorkD = async (name, work) => {
      try {
        setLoading(true)
        const {data} = await axios.post('https://databack-tumt.vercel.app/delete', { name,work,});
  

  
        if (data.success) {
          setMessage(data.message)
          setSneck(true)
         fetchWork()
        } else {
          setMessage('please try again')
          setSneck(true)
        }
      } catch (error) {
        console.error('Error deleting data:', error);
      }finally{
        setLoading(false)
      }
    };


    const handleDelete = async(id) => {
      setLoading(true)
        const {data} = await axios.post(`${url}/enquiry-delete`,{id})
        if(data.success){
          setMessage(data.message)
          setSneck(true)
         fetchData()
        }else{
          setMessage('please try again')
          setSneck(true)
        }
        setLoading(false)
        
        };
    const[open,setOpen] = useState(false)

    const[save,setSave]=useState(()=>{
      const store = localStorage.getItem('route')
      return store?store:'/'
    })


    const[sdata,setSdata] = useState([])

    const fetchStudent = async () => {
      setLoading(true);
  
      try {
        const {data} = await axios.get(`${url}/api/get-file-data`);
       
        setSdata(data);
      } catch (error) {
        console.error('Error fetching attendance data:', error);
        setError('Error fetching attendance data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    const fetchAtten = async () => {
      setLoading(true);
  
      try {
        const {data} = await axios.get(`${url}/attendance`);
        const sortedData = data.sort((a, b) => b._id.localeCompare(a._id));
   
        setAdata(sortedData);
      } catch (error) {
        console.error('Error fetching attendance data:', error);
        setError('Error fetching attendance data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    const[adata,setAdata] = useState([])

    const[passport,setPassport] = useState([])

    const fetchpassport = async () => {
      setLoading(true);
  
      try {
        const {data} = await axios.get(`${url}/passport-info`);
        const sortedData = data.sort((a, b) => b._id.localeCompare(a._id));
   
        setPassport(sortedData);
      } catch (error) {
        console.error('Error fetching attendance data:', error);
        setError('Error fetching attendance data. Please try again.');
      } finally {
        setLoading(false);
      }
    };


    const handleDp = async (id) => {
      setLoading(true);
  
      try {
        const { data } = await axios.post(`${url}/passport-delete`, { id })
  
  
  
        if (data.success) {
          setMessage(data.message);
          setSneck(true)
          fetchpassport()
        } else {
          setMessage(data.error);
          setSneck(true)
        }
      } catch (error) {
        console.error('Submission error:', error);
      } finally {
        setLoading(false);
      }
    };

    const [editedData, setEditedData] = useState({ id: '', name: '', work: '', date: '' });
    const [editingId, setEditingId] = useState(null);
  
    const handleEdit = (item) => {
      setEditingId(item._id);
      setEditedData({ id: item._id, name: item.name, work: item.work, date: item.date });
      
    };

    const handleSave = async () => {
      setLoading(true)
      try {
        const { data } = await axios.post(`${url}/passport-update`, editedData);
  
        if (data.success) {
          setMessage(data.message)
          setSneck(true)
          setEditingId(null);
          fetchpassport();
        } else {
          setMessage(data.error)
          setSneck(true)
        }
      } catch (error) {
        console.error("Error saving data:", error);
      } finally {
        setLoading(false)
      }
    };

    const handleMove = async (id) => {
      setLoading(true);
  
      try {
        const { data } = await axios.post(`${url}/passport-important`, { id })
  
  
  
        if (data.success) {
          setMessage(data.message);
          setSneck(true)
         fetchpassport()
        } else {
          setMessage(data.error);
          setSneck(true)
        }
      } catch (error) {
        console.error('Submission error:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleNormal = async (id) => {
      setLoading(true);
  
      try {
        const { data } = await axios.post(`${url}/passport-normal`, { id })
  
  
  
        if (data.success) {
          setMessage(data.message);
          setSneck(true)
         fetchpassport()
        } else {
          setMessage(data.error);
          setSneck(true)
        }
      } catch (error) {
        console.error('Submission error:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleStatus = async (id) => {
      setLoading(true);
  
      try {
        const { data } = await axios.post(`${url}/passport-check`, { id })
  
  
  
        if (data.success) {
          setMessage(data.message);
          setSneck(true)
         fetchpassport()
        } else {
          setMessage(data.error);
          setSneck(true)
        }
      } catch (error) {
        console.error('Submission error:', error);
      } finally {
        setLoading(false);
      }
    };

    const[select,setSelect] = useState(false)

    const[important,setImportant] = useState(false)
    const[nmi,setNmi] = useState(false)
    const[status,setStatus]=useState(false)
    const[drop,setDrop]=useState('back')
    const[over,setOver]=useState('0px')
    const[data,setData]=useState([])
    const[id,setId]=useState('')
    const[sends,setSends] = useState(null)

    const handlePassnormal=(pass,i,s)=>{
      setOver('100%')
        setData(pass)
        setId(i)
        setSends(s)
      
    }
  return (
    <MyContext.Provider value={{sends,id,handlePassnormal,data,setData,over,setOver,handleStatus,status,setStatus,drop,setDrop,nmi,setNmi,important,setImportant,select,setSelect,handleNormal,handleMove,handleSave,editedData,editingId,handleEdit, setEditingId, setEditedData,handleDp,fetchpassport,passport,setPassport,fetchAtten,adata,setAdata,fetchStudent,sdata,setSdata,handleWorkD,fetchWork,work,setWork,sneck,setSneck,message,setMessage,handleDelete,enquiryData,fetchData,setEnquiryData,loading,setLoading,open,setOpen,Navigate,save,setSave}}>
    {children}
    </MyContext.Provider>
  )
}

export default Provider