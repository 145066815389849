import React, {  useContext } from 'react';
import './Addwork.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MyContext from '../../Common/Context/MyContext';


const Addwork = () => {

const {loading,setLoading,setSneck,setMessage} = useContext(MyContext)

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);  
       document.querySelector('body').style.overflow='hidden'

      const response = await fetch('https://bottom-seven.vercel.app/passport', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-type': 'application/json',
        },
      });

      const data = await response.json();

      if (!data.success) {
        setMessage(data.error);
        setSneck(true)
      } else {
        setMessage(data.message);
        setSneck(true)
        resetForm();
      }
    } catch (error) {
      console.error('Submission error:', error);
    } finally {
      setLoading(false); 
       document.querySelector('body').style.overflow='auto'
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      date:'',
      idType: '',
      work: '',
    },

    validationSchema: yup.object({
      name: yup.string().required('Name is required'),
      date: yup.string().required('date is required'),
      idType: yup.string().required('ID Type is required'),
      work: yup.string().required('Work is required').min(5),
    }),

    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, { resetForm });
    },
  });

  return (
    <div className='attenp'>
      <div className='atten-form'>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id='name'
            name='name'
            label='Name'
            type='text'
            value={formik.values.name || ''}
            onChange={(e) => {
              const upperCaseValue = e.target.value.toUpperCase();
              formik.handleChange({ target: { name: 'name', value: upperCaseValue } });
            }}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant='standard'
          />

          <TextField
          id='date'
          name='date'
          type='date'
          value={formik.values.date || ''}
          onChange={formik.handleChange}
          error={formik.touched.date && Boolean(formik.errors.date)}
          helperText={formik.touched.date && formik.errors.date}
          variant='standard'
        />

          <FormControl fullWidth>
            <InputLabel id='idTypeLabel'>ID Type</InputLabel>
            <Select
              labelId='idTypeLabel'
              id='idType'
              name='idType'
              value={formik.values.idType || ''}
              onChange={formik.handleChange}
              error={formik.touched.idType && Boolean(formik.errors.idType)}
              variant='standard'
            >
              <MenuItem value='' disabled>Select ID Type</MenuItem>
              <MenuItem value='aadhar'>Aadhar Card</MenuItem>
              <MenuItem value='pan'>PAN Card</MenuItem>
              <MenuItem value='passport'>Passport</MenuItem>
              <MenuItem value='voter'>Voter ID</MenuItem>
              <MenuItem value='driving'>Driving Licence</MenuItem>
              <MenuItem value='students'>Students</MenuItem>
              <MenuItem value='other'>Other</MenuItem>
            </Select>
          </FormControl>

          <TextField
            id='work'
            name='work'
            label='Tracking Number/work'
            multiline
            rows={4}
            value={formik.values.work || ''}
            onChange={formik.handleChange}
            error={formik.touched.work && Boolean(formik.errors.work)}
            helperText={formik.touched.work && formik.errors.work}
            variant='standard'
          />

          <Button
            variant='contained'
            className='SM'
            type='submit'
            disabled={loading}  
          >
            {loading ? 'Sending...' : 'Submit'} 
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Addwork;
