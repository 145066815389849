import { BiCalendar } from "react-icons/bi"
import { BsPassport } from "react-icons/bs"
import { GrFormAdd } from "react-icons/gr"
import { IoIosGitNetwork } from "react-icons/io"
import { IoCarSportOutline } from "react-icons/io5"
import { LiaIdCard } from "react-icons/lia"
import { LuLayoutDashboard, LuSendHorizonal } from "react-icons/lu"
import { MdOutlinePerson } from "react-icons/md"
import { PiCardsThreeLight, PiCreditCardDuotone, PiStudent } from "react-icons/pi"
import { SiPolywork } from "react-icons/si"

const Json = [
    {
      "id": 1,
      "icon": <LuLayoutDashboard/>,
      "route": "/",
      "title": "Home"
    },
    {
      "id": 2,
      "icon": <MdOutlinePerson/>,
      "route": "/enquiry",
      "title": "Enquiry"
    },
    {
      "id": 3,
      "icon": <IoIosGitNetwork/>,
      "route": "/work-accept",
      "title": "Work Accept"
    },
    {
      "id": 4,
      "icon": <PiStudent/>,
      "route": "/student",
      "title": "Students"
    },
    {
      "id": 5,
      "icon": <BiCalendar/>,
      "route": "/attendance",
      "title": "Attendance"
    },
    {
      "id": 6,
      "icon": <BsPassport/>,
      "route": "/passport",
      "title": "Passport"
    },
    {
      "id": 7,
      "icon": <PiCreditCardDuotone/>,
      "route": "/aadhar-card",
      "title": "Aadhar Card"
    },
    {
      "id": 8,
      "icon": <LiaIdCard/>,
      "route": "/pan-card",
      "title": "Pan Card"
    },
    {
      "id": 9,
      "icon": <PiCardsThreeLight/>,
      "route": "/voter-id",
      "title": "Voter Id"
    },
    {
      "id": 10,
      "icon": <IoCarSportOutline/>,
      "route": "/driving-license",
      "title": "Driving License"
    },
    {
      "id": 11,
      "icon": <SiPolywork/>,
      "route": "/student-work",
      "title": "Student Work"
    },
    {
      "id": 12,
      "icon": <LuSendHorizonal/>,
      "route": "/other-work",
      "title": "Other Work"
    },
    {
      "id": 13,
      "icon": <GrFormAdd/>,
      "route": "/add-work",
      "title": "Add Work"
    }
  ]
  

export default Json