import React, { useContext } from 'react'
import './Loader.scss'
import MyContext from '../Context/MyContext'

const Loader = () => {

    const{loading}=useContext(MyContext)
  return (

    <>

    {loading &&
    <div className='main-loader'>
    <div className="loader-line"></div>
    </div>

    }

    </>
  )
}

export default Loader