import React, { useContext, useEffect } from 'react';
import './Workdata.scss';
import { RiDeleteBin6Line } from 'react-icons/ri';
import MyContext from '../../Common/Context/MyContext';

const Workdata = () => {
  const { work, fetchWork, handleWorkD } = useContext(MyContext);

  useEffect(() => {
    fetchWork();
  }, []);

  return (
    <div className="workdata-table">
      {work && work.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Work</th>
              <th>Date</th>
              <th>Link</th>
           
            </tr>
          </thead>
          <tbody>
            {work.map((item) => (
              <React.Fragment key={item._id}>
                <tr>
                  <td>{item.name}</td>
                  <td colSpan="4"></td> 
                </tr>
                {item.works && item.works
                  
                  .filter((workItem) => workItem.link && workItem.link.length !== 0 && workItem.unison !== 'veer')
                  .map((workItem) => (
                    <tr key={`${item._id}-${workItem.work}`}>
                      <td></td>
                      <td>{workItem.work}</td>
                      <td>{new Date(workItem.date).toLocaleDateString('en-GB')}</td>                      <td>
                        <a href={workItem.link} target="_blank" rel="noopener noreferrer">
                          {workItem.link}
                        </a>
                      </td>

                      <div className='overlay'><RiDeleteBin6Line   onClick={() =>handleWorkD(item.name, workItem.work)} /></div>
                     
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No Data Available</p>
      )}
    </div>
  );
};

export default Workdata;
