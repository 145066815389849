import React, { useContext } from 'react'
import './Header.scss'
// import { GiHamburgerMenu } from 'react-icons/gi'

const Header = () => {

  return (
    <div className='header-main'>

   {/* <div className='left'><GiHamburgerMenu /></div>*/}
    <div className='center'>V-Ex Tech Solution</div>
    
    </div>
  )
}

export default Header