import React, { useContext, useEffect } from 'react';
import './Student.scss';
import MyContext from '../../Common/Context/MyContext';

const Student = () => {

  const { sdata, fetchStudent } = useContext(MyContext);

  useEffect(() => {
    fetchStudent();
  }, []);

  return (
    <div className="student-table">
      {sdata && sdata.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Mobile</th>
              <th>Email</th>
              <th>Institute</th>
              <th>Duration</th>
              <th>Fees</th>
              <th>Photo</th>
              <th>Aadhar</th>
              <th>Dob</th>
              <th>Address</th>
              <th>Program</th>
             
            </tr>
          </thead>
          <tbody>
            {sdata.map((item) => (
              <tr key={item._id}>
                <td>{item.name}</td>
                <td>{item.mobile}</td>
                <td>{item.email}</td>
                <td>{item.institutename}</td>
                <td>{item.duration}</td>
                <td>{item.fees}</td>
                
                <td>
                        <a href={item.photo} target="_blank" rel="noopener noreferrer">
                        Photo
                        </a>
                      </td>
                      <td>
                      <a href={item.aadhar} target="_blank" rel="noopener noreferrer">
                      Aadhar
                      </a>
                    </td>
                <td>{new Date(item.dob).toLocaleDateString()}</td>
                <td>{item.presentadd}</td>
                <td>{item.techopted}</td>
                
                 
                
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No Data Available</p>
      )}
    </div>
  );
};

export default Student;
