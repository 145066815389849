import React, { useContext, useEffect } from 'react'
import './Attendance.scss'
import MyContext from '../../Common/Context/MyContext'


const Attendance = () => {

  const { adata, fetchAtten } = useContext(MyContext)

  useEffect(() => {
    fetchAtten()
  }, [])

  return (
    <div className="attendance-table">

    {adata && adata.length>0?
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Work</th>
           
          </tr>
        </thead>
        <tbody>
          {adata.map((item) => (
            <tr key={item._id}>
              <td>{item.name}</td>
              <td>{item.date}</td>
              <td>{item.work}</td>
              
            </tr>
          ))}
        </tbody>
      </table>:
      <p>No Data Available</p>

        }
    </div>
  )
}

export default Attendance
