import React, { useContext } from 'react'
import './Select.scss'
import {MdOutlineLabelImportant } from 'react-icons/md'
import { RiEmotionNormalFill } from 'react-icons/ri'
import { BiSolidShow } from 'react-icons/bi'
import MyContext from '../Context/MyContext'
import { FaArrowLeftLong } from 'react-icons/fa6'

const Select = () => {

    const{setImportant,setNmi,setDrop,setStatus} = useContext(MyContext)

  
  return (

    
    <div className='select-main'>
    <li onClick={()=>setImportant(true) || setNmi(false) || setStatus(false) || setDrop('important')}> <MdOutlineLabelImportant /> Important</li>
    <li onClick={()=>setNmi(true) || setImportant(false) || setStatus(false) || setDrop('normal')}> <RiEmotionNormalFill /> Normal</li>
    <li onClick={()=>setNmi(false) || setImportant(false) || setStatus(true) || setDrop('status')}> <BiSolidShow /> Status</li>
    <li onClick={()=>setNmi(false) || setImportant(false) || setStatus(false) || setDrop('back')}> <FaArrowLeftLong /> Back</li>
    </div>
  )
}

export default Select