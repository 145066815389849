// App.js
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import './App.scss'
import Home from './Sidebar/Home/Home';
import Header from './Common/Header/Header';
import Provider from './Common/Context/Provider';
import Enquiry from './Sidebar/Enquiry/Enquiry';
import Loader from './Common/Loader/Loader';
import Sneck from './Common/Sneck/Sneck';
import Workdata from './Sidebar/Workdata/Workdata';
import Student from './Sidebar/Students/Student';
import Attendance from './Sidebar/Attendance/Attendance';
import Passport from './Sidebar/Passport/Passport';
import Aadhar from './Sidebar/Aadhar/Aadhar';
import Pan from './Sidebar/Pan/Pan';
import Voter from './Sidebar/Voter/Voter';
import Driving from './Sidebar/Driving/Driving';
import Swork from './Sidebar/Swork/Swork';
import Other from './Sidebar/Other/Other';
import Addwork from './Sidebar/Addwork/Addwork';


const App = () => {

  return (

   <BrowserRouter>
   <Provider>
   <Loader/>
   <Sneck/>
   <div className='app-main'>
<Header/>
      <div className="flex">
    <Sidebar/>


  <div className='main'>
      <Routes>




      <Route path="/" element={<Home/>} />
      <Route path="/enquiry" element={<Enquiry/>} />
      <Route path="/work-accept" element={<Workdata/>} />
      <Route path="/student" element={<Student/>} />
      <Route path="/attendance" element={<Attendance/>} />
      <Route path="/passport" element={<Passport/>} />
      <Route path="/aadhar-card" element={<Aadhar/>} />
      <Route path="/pan-card" element={<Pan/>} />
      <Route path="/voter-id" element={<Voter/>} />
      <Route path="/driving-license" element={<Driving/>} />
      <Route path="/student-work" element={<Swork/>} />
      <Route path="/other-work" element={<Other/>} />
      <Route path="/add-work" element={<Addwork/>} />
      
   
   
      
      </Routes>

      </div>
      
    </div>

    </div>

    </Provider>

    </BrowserRouter>

    

  );
}

export default App;
